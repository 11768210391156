import React from "react";

import styles from "./progress.module.scss";

export const ProgressComponent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loading_animation}>
        <p className={styles.loading_text}>Please wait...</p>
      </div>
    </div>
  );
};
