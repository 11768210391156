import React, { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DecisionTreeData from "../DecisionTree.json";
import { CriteriaItem } from "../model/DecisionTree.model";
import { ReduxState } from "../../../core/redux";
import { RefundRequest, RefundRequestLineItem } from "../model/RefundRequest.model";
import { RefundRequestActionCreator } from "../action/RefundRequestAction";

import { OrderDetail } from "./OrderDetail";
import styles from "./refund_request.module.scss";

export interface PropData {
  index: number;
  value: string;
}

interface StateData {
  approved?: boolean;
  bulkReason: string;
}

export const Orders = () => {
  const refundData = useSelector<ReduxState, RefundRequest | undefined>((state) => state.refundRequest.requestData);
  if (!refundData) {
    throw new Error("Missing refundData in Orders!");
  }

  const dispatch = useDispatch();
  const orders: RefundRequestLineItem[] = refundData.RefundRequestLineItems || [];
  const unSelectedOrders = orders.some((o) => !(o.visualStates && o.visualStates.selected));
  const selectedOrders = orders.some((o) => o.visualStates && o.visualStates.selected);
  const disableBulkDecision = orders.some(
    (o) =>
      o.visualStates &&
      (o.visualStates.approved === undefined || (o.visualStates.approved !== undefined && o.visualStates.selected === undefined))
  );
  const [stateData, setStateData] = useState<StateData>({ bulkReason: "" });

  const reasonTypes = DecisionTreeData.filter((dt) =>
    stateData.approved ? dt.HasApproveCriteria === "Yes" : stateData.approved === false ? dt.HasDenyCriteria === "Yes" : false
  ).map((item, index) => (
    <option key={index} value={item.DecisionType}>
      {item.DecisionTitle}
    </option>
  ));

  const onSelectAll = (e: FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    if (!isChecked) {
      const bulkRefundType: HTMLSelectElement = document.getElementById("bulkRefundType") as HTMLSelectElement;

      if (bulkRefundType) {
        bulkRefundType.selectedIndex = 0;
      }

      if (stateData) {
        setStateData({ approved: undefined, bulkReason: "" });
      }
    }

    orders.forEach((order, index) => {
      order.visualStates = {};

      if (order.visualStates.selected !== isChecked) {
        order.visualStates.selected = isChecked;
        RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
          index: index,
          visualState: isChecked ? order.visualStates : {}
        });
      }
    });
  };

  const onApproveDeny = (approved: boolean) => {
    orders.forEach((order, index) => {
      if (!(order.visualStates && order.visualStates.selected)) {
        return;
      }

      order.visualStates.approved = approved;
      const refundAmount = order.TotalAmountInUsd ? order.TotalAmountInUsd : order.TotalAmount;
      order.visualStates.refundAmount = approved ? Number(Number(refundAmount).toFixed(2)) : 0;
      // First dropdown
      order.visualStates.refundTypeList = DecisionTreeData.filter((dt) =>
        approved ? dt.HasApproveCriteria === "Yes" : dt.HasDenyCriteria === "Yes"
      );
      order.visualStates.refundType = "";
      // Second dropdown
      order.visualStates.criteriaList = [];
      order.visualStates.refundCriteriaType = "";
      order.visualStates.refundCriteria = "";

      RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({ index: index, visualState: order.visualStates });
    });
    setStateData({ approved: approved, bulkReason: "" });
  };

  function filterCriteria(DecisionType: string, itemType: string, action: string): CriteriaItem[] {
    let _criteriaItems: Array<CriteriaItem> = [];
    if (DecisionType !== "" && DecisionType === "ProductTypeRefund") {
      DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
        refundTypes.RefundTypes.forEach((refundType) => {
          if (refundType.OrderItemTypeList.includes(itemType)) {
            _criteriaItems.push(...refundType.CriteriaItems);
          }
        });
      });
      if (_criteriaItems.length === 0) {
        DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
          refundTypes.RefundTypes.forEach((refundType) => {
            if (refundType.OrderItemTypeList.includes("Other")) {
              _criteriaItems.push(...refundType.CriteriaItems);
            }
          });
        });
      }
    } else {
      DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
        refundTypes.RefundTypes.forEach((refundType) => {
          _criteriaItems.push(...refundType.CriteriaItems);
        });
      });
    }
    if (_criteriaItems.length > 0) {
      _criteriaItems = _criteriaItems.filter((ti) => ti.Action === action);
    }

    return _criteriaItems;
  }

  const onBulkRefundTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    orders.forEach((order, index) => {
      if (order.visualStates && order.visualStates.selected) {
        order.visualStates.refundType = e.currentTarget.value;
        order.visualStates.criteriaList = filterCriteria(
          order.visualStates.refundType,
          order.OrderItemType ? order.OrderItemType : order.ProductType,
          order.visualStates.approved ? "Approve" : "Deny"
        );
        order.visualStates.refundCriteria = "";
        RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
          index: index,
          visualState: order.visualStates || { refundType: e.currentTarget.value }
        });
      }
    });
    setStateData({ approved: stateData.approved, bulkReason: e.currentTarget.value });
  };

  return (
    <div id="Orders" className={styles.orders_grid_overall}>
      <div className={styles.bulk_approval_grid}>
        <div className={styles.bulk_grid_item}>
          <span id="totalItems" className={styles.total_items}>
            {orders.length}
          </span>
          <label>{orders.length === 1 ? "item" : "items"} </label>
        </div>
        <div className={styles.bulk_grid_item}>
          <button
            id="btnBulkApprove"
            disabled={!selectedOrders}
            className={[styles.c_button, styles.f_primary, styles.c_glyph, styles.glyph_approve].join(" ")}
            onClick={(_e) => onApproveDeny(true)}>
            Approve
          </button>
        </div>
        <div className={styles.bulk_grid_item}>
          <button
            id="btnBulkDeny"
            disabled={!selectedOrders}
            className={[styles.c_button, styles.f_primary, styles.c_glyph, styles.glyph_deny].join(" ")}
            onClick={(_e) => onApproveDeny(false)}>
            Deny
          </button>
        </div>
        <div className={styles.bulk_grid_item}>
          <div className={styles.divider} />
        </div>
        <div className={styles.bulk_grid_item}>
          <label>Decision</label>
          <div className={styles.c_select}>
            <select
              id="bulkRefundType"
              onChange={(_e) => onBulkRefundTypeChange(_e)}
              disabled={disableBulkDecision}
              className={!disableBulkDecision ? styles.option_change : ""}
              value={stateData.bulkReason}
              aria-label="Select a bulk decision">
              <option key={0} value="">
                Select a decision
              </option>
              {disableBulkDecision ? null : reasonTypes}
            </select>
          </div>
        </div>
      </div>
      <div className={styles.orders_grid}>
        <div className={styles.orders_grid_row}>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>
            <div className={styles.c_checkbox}>
              <label className={styles.c_label}>
                <input
                  type="checkbox"
                  id={"check"}
                  aria-label={"select all items"}
                  name={"check"}
                  data-id="check"
                  disabled={orders.length < 2}
                  onChange={(_e) => onSelectAll(_e)}
                  checked={!unSelectedOrders}
                  autoFocus
                />
                <span hidden={orders.length < 2} />
              </label>
            </div>
          </div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Order ID</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Order date (UTC)</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Title</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Type</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Total amount</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Actions</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Decision</div>
          <div className={[styles.grid_header, styles.orders_grid_item].join(" ")}>Based on</div>
          <div className={[styles.grid_header, styles.orders_grid_item, styles.hide].join(" ")}>Refund</div>
        </div>
        {orders &&
          orders.map((order, index) => (
            <div key={index} className={styles.orders_grid_row}>
              <OrderDetail index={index} key={index} />
            </div>
          ))}
      </div>
    </div>
  );
};
