import { HttpError, RequestState } from "@xac/sdk";

import { CriteriaItem, DecisionTree } from "./DecisionTree.model";
import { UserDataPointsModel } from "./UserDataPoints.model";

export enum RefundRequestStatus {
  Unknown = "Unknown",
  New = "New",
  SentToAutomation = "SentToAutomation",
  SentToAdvocate = "SentToAdvocate",
  Complete = "Complete",
  AdvocateSubmitted = "AdvocateSubmitted"
}

export interface RefundRequest {
  PreferredEmail: string;
  Puid: string;
  RefundRequestId: string;
  RequestDate: string;
  CaseNumber: string;
  FirstName: string;
  LastName: string;
  RefundRequestLineItems?: RefundRequestLineItem[];
  UserReason?: string;
  UserReasonKey: string;
  UserSubReason?: string;
  UserComments?: string;
  RefundRequestStatus?: RefundRequestStatus;
  DataPoints?: UserDataPointsModel;
}
export interface RefundRequestLineItem {
  RefundRequestLineItemId: string;
  OrderId: string;
  Title: string;
  PublisherName: string;
  ProductType: string;
  ProductFamily: string;
  ProductId: string;
  PurchaseDate: string;
  TotalAmount: number;
  CurrencyCode?: any;
  TotalAmountInUsd?: number;
  OrderItemType: string;
  UserReason: string;
  UserReasonKey: string;
  UserSubReason: string;
  UserSubReasonKey: number;
  UserComments?: string;
  Decision: string;
  DecisionReason: string;
  DecisionCriteria?: string;
  CompletedBy: string;
  visualStates?: OrderItemVisualStates;
}

export interface OrderItemVisualStates {
  approved?: boolean;
  refundType?: string;
  refundCriteriaType?: string;
  refundCriteria?: string;
  refundAmount?: number;
  maxRefundAmount?: number;
  selected?: boolean;
  criteriaList?: CriteriaItem[];
  refundTypeList?: DecisionTree[];
  decisionTree?: DecisionTree[];
}

export interface RefundRequestModel {
  requestData?: RefundRequest;
  status: RequestState;
  error?: HttpError;
  customError?:String;
}

export const initialRefundRequestState: RefundRequestModel = {
  status: RequestState.None
};
