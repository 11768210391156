import { combineReducers, compose, createStore } from "redux";

import refundDecisionReducer from "../../features/refundrequest/reducer/RefundDecisionReducer";
import refundRequestReducer from "../../features/refundrequest/reducer/RefundRequestReducer";
import { RefundDecisionsActions } from "../../features/refundrequest/action/RefundDecisionAction";
import { RefundRequestActions } from "../../features/refundrequest/action/RefundRequestAction";
import { dispatchThunkFunction } from "@xac/sdk";

export const reducerPackage = {
  refundRequest: refundRequestReducer,
  refundDecision: refundDecisionReducer
};

const marsActions = {
  RefundDecision: RefundDecisionsActions.setRefundDecision,
  RefundRequest: RefundRequestActions.setRefundRequest,
  RefundRequestUpdateVisualState: RefundRequestActions.updateOrderItemVisualState
};

export type Action = ReturnType<typeof marsActions[keyof typeof marsActions]>;

export type DispatchType = (action: Action | dispatchThunkFunction) => void;

export const rootReducer = combineReducers(reducerPackage);

const _Window: any = window as any;

export function composeWithDevTools() {
  return process.env.REACT_APP_ENV !== "prod"
    ? compose(_Window.__REDUX_DEVTOOLS_EXTENSION__ && _Window.__REDUX_DEVTOOLS_EXTENSION__())
    : compose();
}

export const store = createStore(rootReducer, composeWithDevTools());

export type ReduxState = ReturnType<typeof rootReducer>;
