import moment from "moment";
import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import DecisionTreeData from "../DecisionTree.json";
import { CriteriaItem } from "../model/DecisionTree.model";
import { ReduxState } from "../../../core/redux";
import { RefundRequestLineItem } from "../model/RefundRequest.model";
import { RefundRequestActionCreator } from "../action/RefundRequestAction";
import { DataPoint } from "../model/UserDataPoints.model.js";

import styles from "./refund_request.module.scss";

export interface OrderDetailProps {
  index: number;
}

enum RefundDecisionAction {
  Approve = "approve",
  Deny = "deny"
}

export const OrderDetail = (props: OrderDetailProps) => {
  const ordersData = useSelector<ReduxState, RefundRequestLineItem[]>((state) =>
    state.refundRequest.requestData && state.refundRequest.requestData.RefundRequestLineItems
      ? state.refundRequest.requestData.RefundRequestLineItems
      : []
  );

  const dataPoints = useSelector<ReduxState, DataPoint[]>((state) =>
    state.refundRequest.requestData && state.refundRequest.requestData.DataPoints
      ? state.refundRequest.requestData.DataPoints.DataPoints
      : []
  );

  const orderData = ordersData[props.index];
  orderData.OrderItemType = orderData.OrderItemType ? orderData.OrderItemType : orderData.ProductType;

  const item = dataPoints.find(
    (data) => data.RefundRequestLineItemId === orderData.RefundRequestLineItemId && data.RuleName.includes("TimeSincePurchaseMinimum")
  );

  const duration = item && moment.duration(item.DataPointValue);

  const formatted = moment.utc(duration && duration.asMilliseconds()).format("HH:mm");

  const dispatch = useDispatch();

  const onCheckChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (!orderData.visualStates) {
      orderData.visualStates = {};
    }
    orderData.visualStates.selected = !orderData.visualStates.selected;
    RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
      index: index,
      visualState: orderData.visualStates
    });
  };

  const onActionChange = (isApprove: any, index: number) => {
    setAction(isApprove ? RefundDecisionAction.Approve : RefundDecisionAction.Deny, index);
  };

  function setAction(action: RefundDecisionAction, index: number) {
    if (!orderData.visualStates) {
      orderData.visualStates = {};
    }

    const approved = action === RefundDecisionAction.Approve;
    orderData.visualStates.approved = approved;
    if (approved) {
      orderData.visualStates.refundTypeList = DecisionTreeData.filter(function (dt) {
        return dt.HasApproveCriteria === "Yes";
      });
    } else {
      orderData.visualStates.refundTypeList = DecisionTreeData.filter(function (dt) {
        return dt.HasDenyCriteria === "Yes";
      });
    }

    if (orderData.visualStates!.refundType) {
      orderData.visualStates!.criteriaList = filterCriteria(orderData.visualStates!.refundType, orderData.OrderItemType);
    }
    orderData.visualStates.refundType = "";
    orderData.visualStates.refundCriteriaType = "";
    orderData.visualStates.refundCriteria = "";

    const refundAmount = orderData.TotalAmountInUsd ? orderData.TotalAmountInUsd : orderData.TotalAmount;
    orderData.visualStates.refundAmount = approved ? Number(Number(refundAmount).toFixed(2)) : 0;
    orderData.visualStates.maxRefundAmount = orderData.visualStates.refundAmount;
    RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
      index: index,
      visualState: orderData.visualStates
    });
  }

  const onRefundTypeChange = (e: ChangeEvent<HTMLSelectElement>, index: number, itemType: string) => {
    if (!orderData.visualStates) {
      orderData.visualStates = {};
    }

    orderData.visualStates.refundType = e.currentTarget.value;
    orderData.visualStates.refundCriteriaType = "";
    orderData.visualStates.refundCriteria = "";
    if (orderData.visualStates.refundType !== "" && orderData.visualStates && orderData.visualStates.approved === undefined) {
      e.target.className = styles.option_change;
    } else {
      orderData.visualStates.criteriaList = filterCriteria(e.currentTarget.value, itemType);
    }
    RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
      index: index,
      visualState: orderData.visualStates
    });
  };

  function filterCriteria(DecisionType: string, itemType: string): CriteriaItem[] {
    let _criteriaItems: Array<CriteriaItem> = [];

    if (orderData.visualStates!.approved !== undefined) {
      if (DecisionType !== "" && DecisionType === "ProductTypeRefund") {
        DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
          refundTypes.RefundTypes.forEach((refundType) => {
            if (refundType.OrderItemTypeList.includes(itemType)) {
              _criteriaItems.push(...refundType.CriteriaItems);
            }
          });
        });
        if (_criteriaItems.length === 0) {
          DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
            refundTypes.RefundTypes.forEach((refundType) => {
              if (refundType.OrderItemTypeList.includes("Other")) {
                _criteriaItems.push(...refundType.CriteriaItems);
              }
            });
          });
        }
      } else {
        DecisionTreeData.filter((dType) => dType.DecisionType === DecisionType).forEach((refundTypes) => {
          refundTypes.RefundTypes.forEach((refundType) => {
            _criteriaItems.push(...refundType.CriteriaItems);
          });
        });
      }
      if (_criteriaItems.length > 0) {
        _criteriaItems = _criteriaItems.filter((ti) => ti.Action === (orderData.visualStates!.approved ? "Approve" : "Deny"));
      }
    }
    return _criteriaItems;
  }

  const onRefundCriteriaChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
    if (!orderData.visualStates) {
      orderData.visualStates = {};
    }
    orderData.visualStates.refundCriteria = e.currentTarget.value;
    const _criteriaType = e.currentTarget[e.currentTarget.selectedIndex].getAttribute("data-criteria-type");
    orderData.visualStates.refundCriteriaType = _criteriaType === null ? undefined : _criteriaType;
    if (orderData.visualStates.refundCriteria !== "" && orderData.visualStates && orderData.visualStates.approved === undefined) {
      e.target.className = styles.option_change;
    }
    RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
      index: index,
      visualState: orderData.visualStates
    });
  };

  const onRefundAmountChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (!orderData.visualStates) {
      orderData.visualStates = {};
    }
    const maxLength = e.currentTarget.value.includes(".") ? 7 : 4;
    let refundAmount: number = Number(parseFloat(e.currentTarget.value).toFixed(2));
    if (refundAmount.toString().length < maxLength) {
      refundAmount = isNaN(refundAmount) ? 0 : refundAmount;
      orderData.visualStates.refundAmount = refundAmount > 0 ? refundAmount : 0;
      RefundRequestActionCreator.updateOrderItemVisualState(dispatch)({
        index: index,
        visualState: orderData.visualStates
      });
    }
  };

  const timeSincePurchase = Number(duration && duration.asHours().toFixed(2));
  const days = Number(duration && Math.floor(duration.asDays()));
  const eligibilityDaysInHours = 336;
  const IsEligibilityExceeded = timeSincePurchase >= eligibilityDaysInHours;
  const background = IsEligibilityExceeded ? styles.exceedEligibility : timeSincePurchase <= 2 ? styles.timeSincePurchase : "";

  return (
    <>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <div className={styles.c_checkbox}>
          <label className={styles.c_label}>
            <input
              type="checkbox"
              id={"check" + props.index}
              aria-label={orderData.Title}
              name={"check" + props.index}
              data-id={"check" + props.index}
              disabled={ordersData.length < 2}
              checked={orderData.visualStates && orderData.visualStates.selected === true}
              onChange={(_e) => onCheckChange(_e, props.index)}
            />
            <span hidden={ordersData.length < 2} />
          </label>
        </div>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <span> {orderData.OrderId}</span>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <p>{moment.utc(new Date(orderData.PurchaseDate)).format("MM/DD/YYYY HH:mm:ss")}</p>
        <p className={background}>
          {days >= 1 && <p>{`${days} ${days === 1 ? "day" : "days"}`}</p>}
          <p>{!Number.isNaN(timeSincePurchase) ? `${formatted} ${timeSincePurchase === 1 ? "hour" : "hours"}` : "n/a"}</p>
          {IsEligibilityExceeded && <p>Over 14 days</p>}
        </p>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <span className={styles.productTitle}>{orderData.Title}</span>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>{orderData.OrderItemType}</div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <p>{orderData.TotalAmount + " (" + orderData.CurrencyCode + ")"}</p>
        {orderData.CurrencyCode !== "USD" && <p>{orderData.TotalAmountInUsd && orderData.TotalAmountInUsd.toFixed(2).concat(" (USD)")}</p>}
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <span className={styles.actions_divider} />
        <button
          id={"btnApprove" + props.index}
          aria-label={"Approve " + orderData.Title}
          value={RefundDecisionAction.Approve}
          data-id={"accept" + props.index}
          data-checked={orderData.visualStates && orderData.visualStates.approved === true}
          onClick={(_e) => onActionChange(true, props.index)}
          className={[styles.c_flipper, styles.f_accept].join(" ")}></button>
        <button
          id={"btnDeny" + props.index}
          aria-label={"Deny " + orderData.Title}
          value={RefundDecisionAction.Deny}
          data-id={"cancel" + props.index}
          data-checked={orderData.visualStates && orderData.visualStates.approved === false}
          onClick={(_e) => onActionChange(false, props.index)}
          className={[styles.c_flipper, styles.f_cancel].join(" ")}></button>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <div className={[styles.c_select, styles.asterisk].join(" ")}>
          <select
            className={
              orderData.visualStates && orderData.visualStates.approved === undefined
                ? ""
                : orderData.visualStates && orderData.visualStates.approved === false
                ? styles.deny_option_change
                : styles.approve_option_change
            }
            id={"refundType" + props.index}
            disabled={orderData.visualStates!.approved === undefined}
            value={orderData.visualStates && orderData.visualStates.refundType}
            onChange={(_e) => onRefundTypeChange(_e, props.index, orderData.OrderItemType)}
            aria-label={"select decision for " + orderData.Title}>
            <option key={0} value="">
              Select a decision
            </option>
            {orderData.visualStates!.refundTypeList &&
              orderData.visualStates!.refundTypeList.map((item, index) => (
                <option key={index} value={item.DecisionType}>
                  {item.DecisionTitle}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item].join(" ")}>
        <div className={[styles.c_select, styles.asterisk].join(" ")}>
          <select
            className={
              orderData.visualStates && orderData.visualStates.approved === undefined
                ? ""
                : orderData.visualStates && orderData.visualStates.approved === false
                ? styles.deny_option_change
                : styles.approve_option_change
            }
            disabled={orderData.visualStates!.approved === undefined}
            id={"Criteria" + props.index}
            aria-label={"select refund criteria for " + orderData.Title}
            value={orderData.visualStates && orderData.visualStates.refundCriteria}
            onChange={(_e) => onRefundCriteriaChange(_e, props.index)}>
            <option key={0} value="">
              Select a criteria
            </option>
            {orderData.visualStates!.criteriaList &&
              orderData.visualStates!.criteriaList.map((item, index) => (
                <option key={index} value={item.Notes} data-criteria-type={item.Type}>
                  {item.Title}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className={[styles.grid_column, styles.orders_grid_item, styles.hide].join(" ")}>
        <div className={orderData.visualStates && orderData.visualStates.approved ? styles.asterisk : ""}>
          <span className={styles.refund_Symbol}>$</span>
          <input
            className={[styles.refund_textbox, orderData.visualStates && orderData.visualStates.approved ? styles.text_change : ""].join(
              " "
            )}
            disabled={orderData.visualStates && !orderData.visualStates.approved}
            id={"txtRefund" + props.index}
            aria-label={"Refund amount for " + orderData.Title}
            type="number"
            pattern="00.00"
            step="00.00"
            min="00.00"
            value={(orderData.visualStates && orderData.visualStates.refundAmount) || ""}
            onChange={(_e) => onRefundAmountChange(_e, props.index)}
          />
        </div>
      </div>
    </>
  );
};
