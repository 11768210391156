import { loggerService, RequestState, UserReasonKey, UserSubReasonKey } from "@xac/sdk";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import modalStyles from "../../sharedcomponents/modal.module.scss";
import { ProgressComponent } from "../../sharedcomponents/ProgressComponent";
import { ReduxState } from "../../../core/redux";
import { RefundDecisionsActions } from "../action/RefundDecisionAction";
import { RefundDecisionModel } from "../model/RefundDecisions.model";
import { RefundRequest, RefundRequestLineItem } from "../model/RefundRequest.model";
import { RefundRequestActionCreator } from "../action/RefundRequestAction";
import ErrorCodes from "../../../core/errors/ErrorCodes"

import styles from "./refund_request.module.scss";

const canSubmit = (orders?: RefundRequestLineItem[]) => {
  return (
    orders &&
    orders.length > 0 &&
    !orders.some(
      (o) =>
        !(
          o.visualStates &&
          o.visualStates.refundType &&
          o.visualStates.refundCriteria &&
          o.visualStates.refundCriteriaType &&
          o.visualStates.approved !== undefined
        )
    )
  );
};

const getIssue = (userReasonKey: string) => {
  switch (userReasonKey) {
    case UserReasonKey.AccidentalPurchase:
      return "Accidental purchase";
    case UserReasonKey.BillingError:
      return "Billing error";
    case UserReasonKey.PurchasedContentDoesNotWork:
      return "Purchased content doesn't work";
    case UserReasonKey.IDidNotMakeThePurchase:
      return "I did not make the purchase";
    case UserReasonKey.Subscription:
      return "Subscription";
    case UserReasonKey.CancelPreorder:
      return "Cancel a preorder";
    case UserReasonKey.Unknown:
    default:
      return "";
  }
};

const getSubIssue = (userSubReason: string) => {
  switch (userSubReason) {
    case UserSubReasonKey.IncorrectItemPurchased:
      return "Incorrect item purchased";
    case UserSubReasonKey.IncorrectVersionPurchased:
      return "Incorrect version purchased";
    case UserSubReasonKey.AlreadyOwnAllOrPartOfTheContent:
      return "Already own all or part of the content";
    case UserSubReasonKey.UsedWrongAccountForPurchase:
      return "Used wrong account for purchase";
    case UserSubReasonKey.DuplicateChargesForSameItem:
      return "Duplicate charges for same item";
    case UserSubReasonKey.IncorrectPriceCharged:
      return "Incorrect price charged";
    case UserSubReasonKey.IncorrectTaxApplied:
      return "Incorrect tax applied";
    case UserSubReasonKey.MyChild:
      return "My child";
    case UserSubReasonKey.AnotherChild:
      return "Another child";
    case UserSubReasonKey.AdultInMyFamily:
      return "Adult in my family";
    case UserSubReasonKey.FriendOrOtherKnownAdult:
      return "Another known adult";
    case UserSubReasonKey.UnknownPerson:
      return "Unknown person";
    case UserSubReasonKey.SlowPerformance:
      return "Slow performance";
    case UserSubReasonKey.WillNotLaunch:
      return "Will not launch";
    case UserSubReasonKey.DoesNotMatchDescription:
      return "Does not match description";
    case UserSubReasonKey.MissingContent:
      return "Missing content";
    case UserSubReasonKey.GameIsOnGamePass:
      return "Game is on Game Pass";
    case UserSubReasonKey.BoughtTheWrongVersion:
      return "Bought the wrong version";
    case UserSubReasonKey.UsedTheWrongPaymentMethod:
      return "Used the wrong payment method";
    case UserSubReasonKey.INoLongerWantTheItem:
      return "I no longer want the item";
    case UserSubReasonKey.Unknown:
    default:
      return "";
  }
};

export const getApprovedItemTotal = (orders: RefundRequestLineItem[]): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  let total = 0.0;
  const approvedOrders = orders.filter((o) => o.visualStates && o.visualStates.approved);
  approvedOrders.forEach((order) => (total += Number(order.visualStates!.refundAmount) || 0.0));
  return formatter.format(total);
};

Modal.setAppElement("#root");

export const RefundRequestDetails = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [loadingModalState, setLoadingModalState] = useState(false);
  const refundData = useSelector<ReduxState, RefundRequest | undefined>((state) => state.refundRequest.requestData);
  const decisionData = useSelector<ReduxState, RefundDecisionModel>((state) => state.refundDecision);

  const startTime = new Date().getTime();

  useEffect(() => {
    if (decisionData.status === RequestState.ServiceFailure) {
      const message = ErrorCodes.errorMessageForRefundDecision(decisionData);
      setLoadingModalState(false);
      setMessage(message);
    } else if (decisionData.status === RequestState.Ready) {
      RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
        status: RequestState.None
      });
      setLoadingModalState(false);
    }
  }, [decisionData.status, dispatch]);

  if (!refundData) {
    return null;
  }

  const {
    CaseNumber: srNumber,
    RequestDate: requestDate,
    UserReasonKey: userReasonKey,
    UserSubReason: userSubReason,
    UserComments: userComments
  } = refundData;
  const orders: RefundRequestLineItem[] = refundData.RefundRequestLineItems || [];

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoadingModalState(true);
    const decisionData: RefundDecisionModel = {
      status: RequestState.None,
      decisionData: refundData
    };
    const endTime = new Date().getTime();
    const timeSpent = endTime - startTime;

    const contentData =
      decisionData.decisionData &&
      decisionData.decisionData.RefundRequestLineItems &&
      decisionData.decisionData.RefundRequestLineItems.map((decision) => ({
        title: decision.Title,
        type: decision.OrderItemType,
        approved: decision.visualStates && decision.visualStates.approved,
        decision: decision.visualStates && decision.visualStates.refundType,
        criteria: decision.visualStates && decision.visualStates.refundCriteriaType
      }));

    loggerService.pushContentUpdate({
      content: {
        areaName: "RefundRequestDetails",
        contentId: "RefundSubmission",
        contentData: {
          timeSpentOnPage: timeSpent,
          refundRequestId: refundData.RefundRequestId,
          orderItems: contentData
        }
      }
    });
    dispatch(RefundDecisionsActions.setRefundDecision(decisionData));
  };
  return (
    <div id="header_details">
      {message.length > 0 ? (
        <div className={styles.error_message}>{message}</div>
      ) : (
        <div className={styles.header_fields_container}>
          <div className={styles.header_fields_left}>
            <div className={styles.stack}>
              <div className={styles.stack_item}>
                <label>Case Number</label>
                <div>{srNumber}</div>
              </div>
              <div className={styles.stack_item}>
                <label>Date Requested (UTC) </label>
                <div>{moment.utc(new Date(requestDate)).format("MM/DD/YYYY HH:mm:ss")}</div>
              </div>
            </div>
            <div className={styles.stack}>
              <div className={styles.stack_item}>
                <label>Issue</label>
                <div>{getIssue(userReasonKey)}</div>
              </div>
              <div className={styles.stack_item}>
                <label>Sub issue</label>
                <div>{getSubIssue(userSubReason ? userSubReason : "Unknown")}</div>
              </div>
              <div className={styles.stack_item}>
                <label>Request details</label>
                <div>{userComments}</div>
              </div>
            </div>
          </div>
          <div className={styles.header_fields_right}>
            <div className={styles.stack}>
              <div className={styles.stack_item}>
                <label>Items to Approve</label>
                <div>{orders.filter((o) => o.visualStates && o.visualStates.approved).length}</div>
              </div>
              <div className={[styles.stack_item].join(" ")}>
                <label>Total to approve</label>
                <div id="total">
                  <span>{getApprovedItemTotal(orders)}</span>
                </div>
              </div>
              <div>
                <div>
                  <button
                    id="btnSubmit"
                    className={[styles.c_button, styles.f_primary].join(" ")}
                    disabled={!canSubmit(orders)}
                    onClick={(_e) => onSubmit(_e)}>
                    Submit
                  </button>
                  <Modal isOpen={loadingModalState} className={modalStyles.modal} overlayClassName={modalStyles.overlay}>
                    <ProgressComponent />
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
