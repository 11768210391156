import { ActionType } from "@xac/sdk";

import { initialRefundRequestState, RefundRequestModel } from "../model/RefundRequest.model";
import { OrderItemVisualStateUpdate } from "../action/RefundRequestAction";
import { Action } from "../../../core/redux";

export default function refundRequestReducer(state = initialRefundRequestState, action: Action): RefundRequestModel {
  switch (action.type) {
    case ActionType.RefundRequest:
      return { ...action.payload };

    case ActionType.RefundRequestUpdateVisualState:
      const data: OrderItemVisualStateUpdate = { ...action.payload };

      const newOrders = [
        ...(state.requestData && state.requestData.RefundRequestLineItems ? state.requestData.RefundRequestLineItems : [])
      ];
      if (state.requestData && newOrders && newOrders[data.index]) {
        newOrders[data.index].visualStates = data.visualState;
        newOrders[data.index].DecisionCriteria = data.visualState.refundCriteriaType;
        return {
          ...state,
          requestData: {
            ...state.requestData,
            RefundRequestLineItems: newOrders
          }
        };
      }

      // We're missing some data.  Just return the state as-is.
      return state;
    default:
      return state;
  }
}
