import { ActionType } from "@xac/sdk";

import { initialDecisionState, RefundDecisionModel } from "./../model/RefundDecisions.model";
import { Action } from "../../../core/redux";

export default function refundDecisionReducer(state = initialDecisionState, action: Action): RefundDecisionModel {
  switch (action.type) {
    case ActionType.RefundDecision:
      return { ...action.payload };
    default:
      return state;
  }
}
