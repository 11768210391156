import { HttpError, RequestState } from "@xac/sdk";

import { RefundRequest } from "./RefundRequest.model";
import { XARSError } from "./XARSError";

export enum RefundDecision {
  NoDecision = "NoDecision",
  Denied = "Denied",
  Approved = "Approved"
}

export interface RefundDecisionModel {
  status: RequestState;
  decisionData?: RefundRequest;
  error?: Error | XARSError | HttpError;
}

export interface AdvocateConclusionLineItem {
  RefundRequestLineItemId?: string;
  Decision?: string;
  DecisionType?: string;
  Criteria?: string;
}

export interface AdvocateConclusion {
  RefundRequestId: string;
  AdvocateConclusionLineItems?: AdvocateConclusionLineItem[];
}

export const initialDecisionState: RefundDecisionModel = {
  status: RequestState.None
};
