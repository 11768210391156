export class XARSError extends Error {
  constructor(m: string, c: XARSErrorCode) {
    super(m);
    this.code = c;
    Object.setPrototypeOf(this, XARSError.prototype);
  }
  code?: XARSErrorCode;
  error?: Error;
}

export enum XARSErrorCode {
  CouldNotFindARefundById = "2000101",
  CouldNotFindAUserOrderById = "2000102",
  IdWasNullOrEmpty = "4000101",
  GuidCouldNotBeParsed = "4000102", 
  MultipleConclusionsForRefundRequestLineItem = "4000121",
  RequestObjectShouldBeSet = "4000103",
  InvalidRefundRequestId = "4000104",
  InvalidTitleField = "4000105",
  XuidCouldNotBeParsedToALongInteger = "4010101",
  PuidCouldNotBeParsedToALongInteger = "4010102",
  TheAuthorizationHeaderWasInvalid = "4010103",
  PuidCouldNotBeParsedToALongInteger2 = "4010105",
  InvalidTokenXUIDAndPUIDRequired = "4010106",
  ThereWasAnUnexpectedError = "5000101",
  ThereWasAnErrorWithCosmosDB = "5000102",
  TokenXUIDIsNotAssociatedWithTheRequestedRefund = "4010107",
  TokenIsExpired = "4010108",
  TokenCouldNotBeValidated = "4010109",
  TokenHasCausedAnUnexpectedError = "4010110"
}
