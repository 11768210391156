import moment from "moment";
import React, { useState } from "react";

import DecisionTreeData from "../DecisionTree.json";
import { CriteriaItem } from "../model/DecisionTree.model";
import { RefundRequest, RefundRequestLineItem } from "../model/RefundRequest.model";

import styles from "./refund_request.module.scss";
export const RefundSummaryComponent = (props: RefundRequest) => {
  const [coppiedMessage, setMessage] = useState("");

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    const txtSummary = document.getElementById("txtSummary") as HTMLTextAreaElement;
    txtSummary.select();
    document.execCommand("copy");
    e.currentTarget.focus();
    setMessage("Your actions log has been copied to the clipboard");
  };

  const orders: RefundRequestLineItem[] = props.RefundRequestLineItems || [];

  function getCriteria(decisionType: any, orderItemType: any, criteria: any, title: any) {
    const _criteriaItems: Array<CriteriaItem> = [];

    DecisionTreeData.filter((dType) => dType.DecisionType === decisionType).forEach((refundTypes) => {
      refundTypes.RefundTypes.forEach((refundType) => {
        if (refundType.OrderItemTypeList.length === 0 || refundType.OrderItemTypeList.includes(orderItemType)) {
          _criteriaItems.push(...refundType.CriteriaItems);
        }
      });
    });

    const item: CriteriaItem | undefined = _criteriaItems.find((element) => element.Type === criteria);

    if (item) {
      item.Notes = title.includes("Fortnite")
        ? item.Notes.replace("2.4 Non Clawback", "2.3 Clawback")
        : item.Notes.replace("2.3 Clawback", "2.4 Non Clawback");
    }

    return item ? item.Notes : criteria;
  }

  function getSummary() {
    let summary = "";
    const completedBy = orders[0].CompletedBy || "Unknown";

    orders.forEach((order) => {
      summary += `Order ${order.OrderId} | ${order.Title} | ${getCriteria(
        order.DecisionReason,
        order.OrderItemType ? order.OrderItemType : order.ProductType,
        order.DecisionCriteria,
        order.Title
      )}| ${order.TotalAmount}(${order.CurrencyCode}) | ${moment.utc(new Date(order.PurchaseDate)).format("MM/DD/YYYY HH:mm:ss")}\n\n${
        order.DecisionReason === "EmergingIssue" ? "Description of emerging issue:\n\n" : ""
      }`;
    });
    summary += `\nAction by: ${completedBy}`;
    return summary;
  }

  return (
    <div className={styles.summary_container}>
      <div className={styles.summary_title}>Refund request #{props.CaseNumber} complete.</div>
      <div className={styles.summary_success_message}>
        <p>
          You have successfully completed refund request #{props.CaseNumber}. Please copy your actions log to the clipboard and paste in the
          CAP tool to conclude this task.
        </p>
      </div>
      <div className={styles.summary_actions_log_title}>Actions Log:</div>

      <textarea aria-label="Actions Log" defaultValue={getSummary()} id={"txtSummary"} className={styles.summary_action_log}></textarea>

      <div className={styles.copybutton_container}>
        <span className={styles.coppied_message}>{coppiedMessage}</span>
        <button
          className={[styles.c_button, styles.f_primary].join(" ")}
          onClick={(_e: React.MouseEvent<HTMLButtonElement>) => copyToClipboard(_e)}>
          Copy log to clipboard
        </button>
      </div>
    </div>
  );
};
