// One Observability @Microsoft
// https://1dsdocs.azurewebsites.net/
// Application Insights:
// https://msasg.visualstudio.com/Shared%20Data/_git/1DS.JavaScript?path=%2Fextensions%2FwebAnalytics%2FREADME.md
import { IAutoExceptionTelemetry, IPageViewTelemetry } from "@microsoft/applicationinsights-common";
import {
  ApplicationInsights,
  IExtendedConfiguration,
  IExtendedTelemetryItem,
  IPageUnloadOverrideValues,
  IPageViewPerformanceTelemetry
} from "@ms/1ds-analytics-web-js";
import {
  ContentUpdateEvent,
  ErrorInfoEvent,
  HttpEvent,
  ILogger,
  InfoEvent,
  PageActionEvent,
  PageViewEvent,
  SessionContentBlob,
  SessionData,
  SurveyEvent
} from "@xac/sdk";

export class OneDSLogger implements ILogger {
  appInsights!: ApplicationInsights;
  // eslint-disable-next-line
  sessionContentBlob: any = {};

  constructor(oneDSConfig: IExtendedConfiguration, writeTelemetryToConsole: boolean) {
    this.appInsights = new ApplicationInsights();
    this.appInsights.initialize(oneDSConfig, []);
    this.initalizeTelemetry();

    if (writeTelemetryToConsole) {
      this.appInsights.addNotificationListener({
        eventsSent: (events) => {
          events.forEach((event) => {
            // eslint-disable-next-line no-console
            console.log("Event sent: ", event);
          });
        },
        eventsDiscarded: (events) => {
          events.forEach((event) => {
            // eslint-disable-next-line no-console
            console.log("Event discarded: ", event);
          });
        }
      });
    }
  }

  initalizeTelemetry() {
    // eslint-disable-next-line
    const telemetryInitializer = (envelope: any) => {
      if (envelope && envelope.data) {
        envelope.data = { ...envelope.data, ...this.sessionContentBlob };

        if (envelope.baseData) {
          envelope.data.destinationUri = envelope.baseData.targetUri || envelope.data.destinationUri;
          envelope.data.referrerUri = envelope.data.referrerUri || envelope.baseData.refUri;

          if (envelope.baseData.content) {
            let baseContent = envelope.baseData.content;

            if (typeof baseContent === "string") {
              baseContent = JSON.parse(envelope.baseData.content);
              const contentCollection: Array<SessionContentBlob> = [];
              const baseContentCollection: Array<SessionContentBlob> = [];

              for (let i = 0; i < baseContent.length; i++) {
                const baseContentElement = baseContent[i];
                const sessionContent: SessionContentBlob = { contentId: "", areaName: "" };

                if (baseContentElement.cN) {
                  baseContentElement.cN = "";
                }

                if (baseContentElement.aN) {
                  sessionContent.areaName = baseContentElement.aN;
                }

                if (baseContentElement.id) {
                  sessionContent.contentId = baseContentElement.id;
                }

                contentCollection.push(sessionContent);
                baseContentCollection.push(baseContentElement);
              }

              envelope.data.content = contentCollection;
              envelope.baseData.content = baseContentCollection;
            } else {
              envelope.data.content = [baseContent];
            }
          }
        }
      }
    };

    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  error(errorEvent: ErrorInfoEvent) {
    this.appInsights._onerror(errorEvent as IAutoExceptionTelemetry);
  }

  info(infoEvent: InfoEvent) {
    const payload = {
      message: infoEvent.message,
      data: infoEvent.data,
      tagId: infoEvent.tag
    };
    this.appInsights.track({ data: { ...payload }, name: "Ms.Web.ClientInfo" } as IExtendedTelemetryItem);
  }

  pushContentUpdate(contentUpdateEvent: ContentUpdateEvent) {
    this.appInsights.trackContentUpdate(contentUpdateEvent);
  }

  pushHttpEvent(httpEvent: HttpEvent) {
    this.appInsights.track({ data: { ...httpEvent }, name: "Ms.Web.HttpEvent" } as IExtendedTelemetryItem);
  }

  pushPageAction(pageActionEvent: PageActionEvent) {
    this.appInsights.trackPageAction(pageActionEvent);
  }

  pushPageUnload() {
    this.appInsights.capturePageUnload({} as IPageUnloadOverrideValues);
  }

  pushPageView(pageViewEvent: PageViewEvent) {
    this.sessionContentBlob = {
      ...this.sessionContentBlob,
      categoryTopic: this.sessionContentBlob.categoryTopic ? undefined : this.sessionContentBlob.categoryTopic,
      solutionType: this.sessionContentBlob.solutionType ? undefined : this.sessionContentBlob.solutionType
    };

    if (pageViewEvent) {
      this.appInsights.trackPageView(pageViewEvent as IPageViewTelemetry);
    } else {
      this.appInsights.trackPageView({} as IPageViewTelemetry);
    }
  }

  pushPageViewPerformance(pageViewPerformanceTelemetry: IPageViewPerformanceTelemetry) {
    this.appInsights.trackPageViewPerformance(pageViewPerformanceTelemetry);
  }

  pushSurvey(surveyEvent: SurveyEvent) {
    this.appInsights.track({ data: { ...surveyEvent }, name: "Ms.Web.Survey" } as IExtendedTelemetryItem);
  }

  setRequiredData(appVersion: string, activeUserCultureInfo: string, activeUserMuid: string) {
    this.sessionContentBlob = {
      ...this.sessionContentBlob,
      appVersion: appVersion,
      activeUserCultureInfo: activeUserCultureInfo,
      activeUserMuid: activeUserMuid
    };
  }

  updateSessionData(sessionData: SessionData) {
    this._updateSessionData(sessionData);
  }

  _updateSessionData(sessionData: SessionData) {
    this.sessionContentBlob = { ...this.sessionContentBlob, ...sessionData };
  }
}
