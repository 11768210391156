import  ErrorCodes  from '../../core/errors/ErrorCodes';
import { BadRequestError, HttpError, loggerService, RequestOptions, RequestState, XaOriginHeader } from "@xac/sdk";
import { useEffect } from "react";
import { adalFetch } from "react-adal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { xarsAuthContext } from "./../../core/auth/AuthContext";
import DecisiondTreeData from "./DecisionTree.json";
import { HttpWrapper } from "./action/HttpWrapper";
import { ReduxState } from "./../../core/redux/index";
import { OrderItemVisualStates, RefundRequest, RefundRequestModel } from "./model/RefundRequest.model";
import { RefundRequestActionCreator } from "./action/RefundRequestAction";
import { UserDataPointsModel } from "./model/UserDataPoints.model";
import { XARSError, XARSErrorCode } from "./model/XARSError";
const refundRequestPath = "/refundrequest";
const refundRequestDataPointsPath = "/refundrequest/dataPoints";

const providerName = "RefundRequestProvider";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export const RefundRequestProvider = () => {
  const query = useQuery();
  const id = query.get("id");
  const initialOrderItemVisualStates: OrderItemVisualStates = {};
  initialOrderItemVisualStates.decisionTree = DecisiondTreeData;
  const request = useSelector<ReduxState, RefundRequestModel>((state) => state.refundRequest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (request.status === RequestState.None) {
      const requestUrl = process.env.REACT_APP_REFUND_REQUEST_URL;
      const xarsClientId = process.env.REACT_APP_REFUND_APP_ID;

      const isTest = id && id.toLowerCase() === "test";

      if (!requestUrl) {
        throw new Error("Missing Refund Request Url in Environment!");
      }

      if (!xarsClientId) {
        throw new Error("Missing XARS Client Id in Environment");
      }

      const options: RequestOptions = {
        url: !isTest ? requestUrl + refundRequestPath + "/" + id : process.env.REACT_APP_REFUND_REQUEST_RESPONSE_URL || "",
        headers: { "XA-Origin": XaOriginHeader.Mars }
      };

      const dataPointsOptions: RequestOptions = {
        url: !isTest ? requestUrl + refundRequestDataPointsPath + "/" + id : process.env.REACT_APP_DATA_POINT_RESPONSE_URL || "",
        headers: { "XA-Origin": XaOriginHeader.Mars }
      };

      adalFetch(xarsAuthContext, xarsClientId, HttpWrapper, options.url, options)
        .then((refundRequestResponse: RefundRequest) => {
          if (refundRequestResponse.RefundRequestLineItems && refundRequestResponse.RefundRequestLineItems.length === 0) {
            throw new XARSError("Case not found.", XARSErrorCode.CouldNotFindARefundById);
          }
          if (refundRequestResponse.RefundRequestLineItems && refundRequestResponse.RefundRequestLineItems.length > 0) {
            refundRequestResponse.RefundRequestLineItems.forEach((li) => (li.visualStates = {}));
            const firstRefundRequestLineItem = refundRequestResponse.RefundRequestLineItems[0];
            refundRequestResponse.UserReason = firstRefundRequestLineItem.UserReason;
            refundRequestResponse.UserReasonKey = firstRefundRequestLineItem.UserReasonKey;
            refundRequestResponse.UserSubReason = firstRefundRequestLineItem.UserSubReason;
            refundRequestResponse.UserComments = firstRefundRequestLineItem.UserComments;
          }
          const requestData: RefundRequestModel = {
            status: RequestState.Ready,
            requestData: refundRequestResponse
          }; 
          // tslint:disable-next-line: no-floating-promises
          adalFetch(xarsAuthContext, xarsClientId, HttpWrapper, dataPointsOptions.url, dataPointsOptions).then(
            (dataPointsResponse: UserDataPointsModel) => {
              refundRequestResponse.DataPoints = dataPointsResponse;
              RefundRequestActionCreator.setRefundRequestResponse(dispatch)(requestData);
            }
          );
          RefundRequestActionCreator.setRefundRequestResponse(dispatch)(requestData);
        })
        .catch((error: HttpError) => { 
          if (error instanceof XARSError) {
            loggerService.error({ error: new Error(`${providerName}: ${error}`) });
            const xarsError = error as XARSError;
            RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
              ...request,
              status: xarsError.code === XARSErrorCode.CouldNotFindARefundById ? RequestState.NotFound : RequestState.ServiceFailure,
              error: xarsError.error 
            });
          }
          if (error.response) {
            const badResponseBody = error.response.body as BadRequestError; 
            if (error.response.status === 400) {  
                loggerService.error({ error: new Error(`${providerName}: ${error}`) });
                RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
                  ...request,
                  status: RequestState.InvalidContent,
                  error: error
                }); 
            }else {
              loggerService.error({ error: new Error(`${providerName}: ${error}`) });
              const xarsError = error as XARSError;
              RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
                ...request,
                status: RequestState.ServiceFailure,
                error: xarsError.error,
                customError:  ErrorCodes.errorMessageForCode(badResponseBody.Error.Code)
              });
            }
          } else if (error instanceof XARSError) {
            loggerService.error({ error: new Error(`${providerName}: ${error}`) });
            const xarsError = error as XARSError;
            RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
              ...request,
              status: xarsError.code === XARSErrorCode.CouldNotFindARefundById ? RequestState.NotFound : RequestState.ServiceFailure,
              error: xarsError.error 
            });
          } else if (error instanceof Error) {
            loggerService.error({ error: new Error(`${providerName}: ${error}`) });
            RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
              ...request,
              status: RequestState.ServiceFailure,
              error: error
            });
          }
        });
    }
  });

  return null;
};
