import { HttpMethod, loggerService, RequestOptions, RequestState, XaOriginHeader } from "@xac/sdk";
import { useEffect } from "react";
import { adalFetch } from "react-adal";
import { useDispatch, useSelector } from "react-redux";

import { xarsAuthContext } from "../../core/auth/AuthContext";
import { ReduxState } from "../../core/redux/index";

import { HttpWrapper } from "./action/HttpWrapper";
import { RefundDecisionsActions } from "./action/RefundDecisionAction";
import { AdvocateConclusion, AdvocateConclusionLineItem, RefundDecision, RefundDecisionModel } from "./model/RefundDecisions.model";
import { XARSError } from "./model/XARSError";

const refundRequestSubmitPath = "/advocateconclusion";

export const RefundDecisionProvider = () => {
  const refundDecision = useSelector<ReduxState, RefundDecisionModel>(state => state.refundDecision);
  const dispatch = useDispatch();

  useEffect(() => {
    // State is None and we have decision data.  Start building the request.
    if (refundDecision.status === RequestState.None && refundDecision.decisionData) {
      const refundData = refundDecision.decisionData;
      const advocateConclusion: AdvocateConclusion = { RefundRequestId: refundData.RefundRequestId };

      if (refundData.RefundRequestLineItems && refundData.RefundRequestLineItems.length > 0) {
        const lineItems: Array<AdvocateConclusionLineItem> = [];

        refundData.RefundRequestLineItems.forEach(refundRequestLineItem => {
          const advocateConclusionLineItem: AdvocateConclusionLineItem = {
            RefundRequestLineItemId: refundRequestLineItem.RefundRequestLineItemId,
            Decision:
              refundRequestLineItem.visualStates && refundRequestLineItem.visualStates.approved
                ? RefundDecision.Approved
                : RefundDecision.Denied,
            DecisionType: refundRequestLineItem.visualStates!.refundType,
            Criteria: refundRequestLineItem.visualStates!.refundCriteriaType
          };
          lineItems.push(advocateConclusionLineItem);
        });

        advocateConclusion.AdvocateConclusionLineItems = lineItems;
      }

      // Prepare the request
      const requestUrl = process.env.REACT_APP_REFUND_REQUEST_URL;
      const xarsClientId = process.env.REACT_APP_REFUND_APP_ID;

      if (!requestUrl) {
        throw new Error("Missing XARS Url in Environment!");
      }

      if (!xarsClientId) {
        throw new Error("Missing XARS Client Id in Environment");
      }

      const options: RequestOptions = {
        url: requestUrl + refundRequestSubmitPath,
        method: HttpMethod.Post,
        headers: { "Content-Type": "application/json", "XA-Origin": XaOriginHeader.Mars },
        data: JSON.stringify(advocateConclusion)
      };

      // Set the state to Loading
      dispatch(RefundDecisionsActions.setRefundDecision({ ...refundDecision, status: RequestState.Loading }));

      // Send the request
      adalFetch(xarsAuthContext, xarsClientId, HttpWrapper, options.url, options)
        // On Request Complete, set State appropriately.
        // - If valid data, set State to 'Ready'
        .then(() => {
          dispatch(RefundDecisionsActions.setRefundDecision({ ...refundDecision, status: RequestState.Ready }));
        })
        // - Otherwise, set the State to an appropriate error type.
        .catch((error: XARSError | Error) => {
          if (error instanceof XARSError) {
            // This means it was one of our processed errors.
            loggerService.error({ error: error });
            dispatch(
              RefundDecisionsActions.setRefundDecision({
                ...refundDecision,
                status: RequestState.ServiceFailure,
                error: error
              })
            );
          } else {
            loggerService.error({ error: error });
            dispatch(
              RefundDecisionsActions.setRefundDecision({
                ...refundDecision,
                status: RequestState.ServiceFailure,
                error: error
              })
            );
          }
        });
    }
  }, [refundDecision, refundDecision.status, refundDecision.decisionData, dispatch]);

  return null;
};
