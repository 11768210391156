import { AuthenticationContext } from "react-adal";

import { AAD_TENANT } from "../../Constants";

const ActiveDirectory = (AppId: string) => {
  return {
      tenant: AAD_TENANT,
      clientId: AppId,
      endpoints: {}
  }
};

export const xarsAuthContext = new AuthenticationContext(ActiveDirectory(process.env.REACT_APP_REFUND_APP_ID!));