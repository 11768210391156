import { IExtendedConfiguration } from "@ms/1ds-analytics-web-js";

export const oneDSLoggerConfig: IExtendedConfiguration = {
  instrumentationKey: "653dedce7d2d4be3a0dc2fe80d691b99-3becae99-8c9f-4e4f-b970-5b92e52e0197-7011",
  webAnalyticsConfiguration: {
    autoCapture: {
      pageView: false,
      onLoad: false,
      onUnload: false,
      click: false,
      scroll: false,
      resize: false,
      jsError: false
    }
  }
};
