import { HttpMethod, HttpService, RequestOptions } from "@xac/sdk";

export const HttpWrapper = (url: string, options: RequestOptions): Promise<any> => {
  if (!options.method) {
    options.method = HttpMethod.Get;
  }
  const props = {
    ...options,
    url: url
  };
  return HttpService.executeRequest<any>(props);
};
