import { loggerService } from "@xac/sdk";
import { runWithAdal } from "react-adal";

import { xarsAuthContext } from "./core/auth/AuthContext";
import { OneDSLogger } from "./core/logger/OneDSLogger";
import { oneDSLoggerConfig } from "./core/logger/oneDSLoggerConfig";

const DO_NOT_LOGIN = false;

runWithAdal(
  xarsAuthContext,
  () => {
    // eslint-disable-next-line
    require("./indexApp.tsx");
  },
  DO_NOT_LOGIN
);

loggerService.initialize(
  [new OneDSLogger(oneDSLoggerConfig, process.env.REACT_APP_WRITE_TELEMETRY_TO_CONSOLE === "true")],
  process.env.REACT_APP_VERSION || "",
  "",
  xarsAuthContext.getCachedUser().profile.aud
);
