import React from "react";

import { RefundRequest } from "../model/RefundRequest.model";
import { DataPoint } from "../model/UserDataPoints.model";

import styles from "./refund_request.module.scss";

export const UserDetails = (props: RefundRequest) => {
  const { FirstName, LastName, PreferredEmail } = props;
  const DataPointsModel = props.DataPoints;
  const DataPoints = DataPointsModel && DataPointsModel.DataPoints;

  const total = DataPoints && DataPoints.find((dp) => dp.DataPointName.includes("GrandTotalConvertedToUSD"));

  //Sanitized The output
  let totalRefundedAmount = total && parseFloat(total.DataPointValue.replace("(USD)", " ")).toFixed(2);

  totalRefundedAmount = totalRefundedAmount && totalRefundedAmount.concat(" (USD)");

  const totalRefunds = DataPoints && (DataPoints.find((dp) => dp.DataPointName === "CountOfRefunds") as DataPoint);
  return (
    <div id="user_details">
      <div className={styles.msa_details}>
        <div className={styles.user_name}>
          <span>{`${FirstName} ${LastName}`}</span>
        </div>
        <div className={styles.stack}>
          <div className={styles.stack_item}>
            <label>Preferred Email</label>
            <div>{PreferredEmail}</div>
          </div>
          <div className={styles.stack_item}>
            <label># of Refunds in last 365</label>
            <div>{totalRefunds && Number(totalRefunds.DataPointValue) > 0 ? totalRefunds.DataPointValue : "0"}</div>
          </div>
          <div className={styles.stack_item}>
            <label>Total Refunded in last 365</label>
            <div>{totalRefundedAmount ? totalRefundedAmount : "Not Available"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
