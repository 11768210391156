import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppErrorBoundary from "./core/errors/AppErrorBoundary";
import { CaseDetails } from "./features/refundrequest/components/CaseDetails";
import "./Generic.scss";
import { Home } from "./Home";
import { RefundDecisionProvider } from "./features/refundrequest/RefundDecisionProvider";
import { RefundRequestProvider } from "./features/refundrequest/RefundRequestProvider";
import Telemetry from "./core/telemetry/Telemetry";

const App = () => {
  useEffect(() => {
    document.title = "MARS : Xbox Refund Tool";
  }, []);

  return (
    <main role="main" id="BodyContent">
      <span style={{ display: "none" }}>{`Version: ${process.env.REACT_APP_VERSION}`}</span>
      <AppErrorBoundary>
        <Router>
          <Switch>
            <Route exact strict path="/">
              <Home></Home>
            </Route>
            <Route path="/refundrequest">
              <Telemetry />
              <RefundRequestProvider />
              <RefundDecisionProvider />
              <CaseDetails />
            </Route>
          </Switch>
        </Router>
      </AppErrorBoundary>
    </main>
  );
};

export default App;
