import { RequestState } from "@xac/sdk";
import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import modalStyles from "../../sharedcomponents/modal.module.scss";
import { ReduxState } from "../../../core/redux";
import { RefundRequestModel, RefundRequestStatus } from "../model/RefundRequest.model";
import { RefundRequestActionCreator } from "../action/RefundRequestAction";

import { Orders } from "./Orders";
import styles from "./refund_request.module.scss";
import { RefundRequestDetails } from "./RefundRequestDetails";
import { RefundSummaryComponent } from "./RefundSummaryComponent";
import { UserDetails } from "./UserDetails";

export const CaseDetails = () => {
  const dispatch = useDispatch();
  const request = useSelector<ReduxState, RefundRequestModel>(state => state.refundRequest);
  const refundStatus = request.requestData && request.requestData.RefundRequestStatus;
  const caseNumber = request.requestData ? request.requestData.CaseNumber : "";

  useEffect(() => {
    if (!request.requestData) {
      RefundRequestActionCreator.setRefundRequestResponse(dispatch)({
        status: RequestState.Loading
      });
    }
  }, [request.requestData, dispatch]);

  useEffect(() => {
    if (caseNumber !== "") {
      document.title = "MARS : Xbox Refund Tool - Case number: " + caseNumber;
    }
  }, [caseNumber]);

  let message: string = ""; 
  if (request.status === RequestState.NotFound) {
    message = "Case not found.";
  } else if (request.status === RequestState.InvalidContent) {
    message = "Case cannot be loaded. Please check the URL and try again.";
  } else if (request.status === RequestState.ServiceFailure) {
    message = request.customError ? request.customError.toString() : "Something went wrong. Please try after sometime.";
  }

  return (
    <div className={styles.case_details}>
      {request.status !== RequestState.Ready || !request.requestData ? (
        <div className={styles.case_not_found}>
          <h4>{message}</h4>
        </div>
      ) : (
        <>
          {refundStatus && (refundStatus === RefundRequestStatus.New || refundStatus === RefundRequestStatus.SentToAdvocate) ? (
            <>
              <div className={styles.heading}>
                <h1>Case Details</h1>
              </div>
              <RefundRequestDetails />
              <UserDetails {...request.requestData} />
              <Orders />
            </>
          ) : (
            <Modal isOpen={true} className={modalStyles.summary_modal} overlayClassName={modalStyles.overlay}>
              <RefundSummaryComponent {...request.requestData}></RefundSummaryComponent>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};
