import { loggerService } from "@xac/sdk";
import { UnregisterCallback } from "history";
import { useEffect, useRef } from "react";
import { RouterProps, withRouter } from "react-router";

type Props = RouterProps;
let unlistenHistory: UnregisterCallback;

const Telemetry = (props: Props) => {
  const pageUri = useRef<string>(window.location.href);

  useEffect(() => {
    loggerService.pushPageView({
      pageName: window.location.pathname,
      pageUri: pageUri.current,
      referrerUri: document.referrer || "appLaunch"
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    unlistenHistory = props.history.listen((location) => {
      loggerService.pushPageUnload();

      loggerService.pushPageView({
        pageName: location.pathname,
        pageUri: window.location.href,
        referrerUri: pageUri.current
      });

      pageUri.current = window.location.href;
    });

    return () => {
      unlistenHistory();
    };
  }, [props.history]);

  return null;
};

export default withRouter(Telemetry);
