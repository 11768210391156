import { HttpError } from "@xac/sdk";

import { RefundDecisionModel } from "./../../features/refundrequest/model/RefundDecisions.model";
export default class ErrorCodes {

  // refundDecision > error > response > body > Error > Code
  static errorMessageForRefundDecision(decision: RefundDecisionModel) {
    const httpError: HttpError = decision.error as HttpError;
    const response = httpError.response;
    if (!response) {
      return this.errorMessageForCode("");
    }
    const body: any = response.body;
    // tslint:disable-next-line:no-string-literal
    const Error = body["Error"]
    if (!Error) {
      return this.errorMessageForCode("");
    }
    // tslint:disable-next-line:no-string-literal
    const Code = Error["Code"];
    if (!Code) {
      return this.errorMessageForCode("");
    }
    return this.errorMessageForCode(Code);
  }

  static errorMessageForCode(code: string) {
    switch (code) {
      case "2000101":
        return "Could not find a refund by Id";
      case "2000102":
        return "Could not find a user order by Id";
      case "4000101":
        return "Id was null or empty";
      case "4000102":
        return "Guid could not be parsed";
      case "4000103":
        return "Request object should be set";
      case "4000104":
        return "Invalid refund request Id";
      case "4000105":
        return "A field in the data model was found to be null, whitespace, or invalid";
      case "4000106":
        return "Response could not be casted to a RefundResponse";
      case "4000109":
        return "Invalid line items in the request.";
      case "4000110":
        return "The request has already been submitted.";
      case "4000111":
        return "Invalid refund request line item Id";
      case "4000112":
        return "Locale is missing.";
      case "4000113":
        return "Could not find user purchases for Puid";
      case "4000114":
        return "Invalid mix of order types in request";
      case "4000116":
        return "Could not find a line item that matches order history";
      case "4000117":
        return "More than one user purchase matches a single order id";
      case "4000118":
        return "PurchaseOrderId was null or empty";
      case "4000119":
        return "One or more request parameters are out of range";
      case "4000120":
        return "Null or empty line items in the request.";
      case "4000121":
        return "There were duplicate refund conclusions found with a Refund Request Line Item Id";
      case "4010101":
        return "Xuid could not be parsed to a long integer";
      case "4010102":
        return "Puid could not be parsed to a long integer";
      case "4010103":
        return "The Authorization Header was invalid";
      case "4010106":
        return "Invalid Token - XUID and PUID required";
      case "4010107":
        return "Token XUID is not associated with the requested refund";
      case "4010108":
        return "Token is expired";
      case "4010109":
        return "Token could not be validated";
      case "4010110":
        return "Token has caused an unexpected error";
      case "4010111":
        return "Assist Token could not be validated";
      case "5000101":
        return "There was an unexpected error";
      case "5000103":
        return "There were duplicate requests found with a Refund Request Id";
      case "5000104":
        return "There were duplicate refund conclusions found with a Refund Request Line Item Id";
      case "5000105":
        return "There was an incorrect count of refund status documents found for the Refund Request Line Item Id";
      case "5030102":
        return "There was an error with creating the Refund Request Document in the Document Manager";
      case "5030103":
        return "There was a task cancelled when trying to create a Refund Request";
      case "5030104":
        return "There was a SocketException when trying to create a Refund Request";
      case "5030105":
        return "There was an Exception when trying to create a Service Token for another service to service call";
      default:
        return "Unknown Error";
    }
  }
}