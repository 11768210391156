import { ActionType, createAction } from "@xac/sdk";

import { DispatchType } from "../../../core/redux";
import { OrderItemVisualStates, RefundRequestModel } from "../model/RefundRequest.model";

export interface OrderItemVisualStateUpdate {
  index: number;
  visualState: OrderItemVisualStates;
}

export abstract class RefundRequestActions {
  static setRefundRequest(response: RefundRequestModel) {
    return createAction(ActionType.RefundRequest, { ...response });
  }

  static updateOrderItemVisualState(data: OrderItemVisualStateUpdate) {
    return createAction(ActionType.RefundRequestUpdateVisualState, { ...data });
  }
}

export abstract class RefundRequestActionCreator {
  static setRefundRequestResponse = (dispatch: DispatchType) => (response: RefundRequestModel) => {
    dispatch(RefundRequestActions.setRefundRequest(response));
  };

  static updateOrderItemVisualState = (dispatch: DispatchType) => (data: OrderItemVisualStateUpdate) => {
    dispatch(RefundRequestActions.updateOrderItemVisualState(data));
  };
}
