import { loggerService } from "@xac/sdk";
import React from "react";
interface State {
  error?: Error;
}
interface Props {
}
class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {}
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }
  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    loggerService.error({ error });
  }
  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (<div style={{padding: 15}}>
        <div style={{backgroundColor: "#FFDEAD", padding: 15}}>
          <h1 style={{color: "red"}}>Something went wrong.</h1>
        </div>
        <h4>Title: {this.state.error.name}</h4>
        <h4>Message: {this.state.error.message}</h4>
        <p style={{textDecorationLine: "underline", marginTop: 15, marginBottom: 15}}>Stack Trace</p>
        <div style={{backgroundColor: "white", padding: 15}}>
          <p style={{color: "grey"}}>{this.state.error.stack}</p>
        </div>
      </div>);
    }
    return this.props.children;
  }
}
export default AppErrorBoundary;