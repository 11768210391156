import React from "react";

import styles from "./home.module.scss";
export const Home = () => {
  return (
    <div className={styles.welcome_container}>
      <div className={styles.welcome_heading}>
        Welcome to MARS!
        <p className={styles.welcome_sub_heading}>In order to use MARS, you must use a link provided in a support ticket.</p>
      </div>
    </div>
  );
};
